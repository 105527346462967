<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
      <!-- <div class="dispflex" v-if="$route.path == '/admission/teacher'">
        <h1 class="hdrtitle" style="margin-top: 0px">School Hub</h1>
        <button type="button" class="backbtn" @click="previousRoute">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div> -->
      <div class="webNew-dispflex"  v-if="$route.path == '/admission/teacher'" >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        School Hub
       </h4>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div
              class="widjethdr dispflex"
              v-if="$route.path == '/admission/teacher'"
            >
              <div style="width: auto" class="flexitm">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-user-tie"></i
                ></span>
                <h1>Manage Teachers & Admin</h1>
              </div>
              <div style="margin-top: 10px" class="flexitm">
              
              </div>
              <input style="left: 7px;top: 4px;" hidden
                            type="file"
                            id="importExportID"
                            ref="schoolImagechangeFile"
                            name="schoolImagechangeFile"
                            accept=".xls,.xlsx"
                            @change="updateFile('schoolImage','schoolImagechangeFile')"
                           />
                <div style="margin-top: 3px" class="flexitm">
                <div class="upload-btn-wrapper" style="border-radius: 40px;" v-if="currentTab == 0">
                  
                        <!-- <button  class="upbtn" style="margin-left: 0px;margin-right: 13px;" >Upload a file</button> -->
                           <!-- <input style="left: 7px;top: 4px;" hidden
                            type="file"
                            id="importExportID"
                            ref="schoolImagechangeFile"
                            name="schoolImagechangeFile"
                            accept=".xls,.xlsx"
                            @change="updateFile('schoolImage','schoolImagechangeFile')"
                           /> -->
                      <!-- </div>  @change="updateFile('schoolImage','schoolImagechangeFile')" -->
                    </div>
                <!-- <input v-if="currentTab == 0" type="text" v-model="searchTeacher" placeholder="Search Teachers">
                        <input v-if="currentTab == 1" v-model="searchUser" type="text" placeholder="Search User">
                        <input v-if="currentTab == 2" type="text" v-model="searchRole" placeholder="Search Role"> -->
              </div>
              <div class="mt-1 flexitm" style="right: 0px;
    margin-right: 18px;
    position: absolute;
    top: 76px;">
                <button
                  v-if="currentTab == 0"
                  class="btn gry_bg tooltipt mpopup twoicn"
                  @click.prevent="downloadFile"
                >
                  <i class="fas fa-download"></i>
                 
                  <span class="tooltiptext">Download Teacher Upload Template</span>
                </button>
                <button
                  v-if="currentTab == 0"
                  class="btn gry_bg tooltipt mpopup twoicn"
                  @click.prevent="uploadbtn"
                >
                  <i class="fas fa-upload"></i>
                 
                  <!-- <i class="fa-solid fa-arrow-up-from-bracket"></i> -->
                  <span class="tooltiptext">Upload</span>
                </button>
                <!-- <button class="btn gry_bg">
                      <i class="fas fa-search"></i>
                    </button> -->
                    <!-- <div class="mt-1" v-if="currentTab == 0"> -->
                 
                <button
                  v-if="currentTab == 0"
                  class="btn gry_bg tooltipt mpopup twoicn"
                  @click="openTeacherPopUp"
                >
                  <i class="fas fa-user-tie user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">Add Teacher</span>
                </button>

                <button
                  v-if="currentTab == 1"
                  class="btn gry_bg tooltipt mpopup twoicn"
                  @click="openUserPoUp"
                >
                  <i class="fas fa-user-tie user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">Create User</span>
                </button>

                <button
                  v-if="currentTab == 2"
                  class="btn gry_bg tooltipt mpopup twoicn"
                  @click="openRolePoUp"
                >
                  <i class="fas fa-user-tie user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">Create Role</span>
                </button>
                <button
                  v-if="currentTab == 3"
                  class="btn gry_bg tooltipt mpopup twoicn"
                  v-b-modal.modalOne
                >
                  <i class="fas fa-user-tie user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">Add Department</span>
                </button>
              </div>
            </div>
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input
                  type="radio"
                  name="tabs"
                  :checked="currentTab == 3 ? 'checked' : ''"
                  @click="setActiveTab(3)"
                  id="deptab"
                />
                <label for="deptab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Manage Department [{{ departmentList.length }}]</label>
                <div class="tab">
                  <div>
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Department Name</div>
          <div class="cell">Last Modified By</div>
          <div class="cell">Last Modified Date</div>
          <div class="cell">Action</div>
                      </div>
                      <div
          class="resrow"
          v-for="(department, index) in departmentList"
          :key="index"
        >
         
          <div class="cell" data-title="Department Name">
            {{ department.departmentName }}
          </div>
            <div class="cell" data-title="Department Name">
            {{ department.updatedBy ? department.updatedBy.firstName + " " +  department.updatedBy.lastName : '' }}
          </div>
          <div  class="cell" data-title="Role">
                {{ department.updatedDate | dateFormat }}
              </div>
          <div class="cell" data-title="Action">
            <button
              class="btn round orange tooltipt"
              @click="showUpdateModal(index)"
            >
              <i class="fas fa-edit"></i
              ><span class="tooltiptext">Edit</span>
            </button>
              <button class="btn round red tooltipt" @click="showDeleteModal(department._id)"  >
                    <i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
                </button>
          </div>
        </div>
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  @click="setActiveTab(0)"
                  id="classestab"
                  :checked="currentTab == 0 ? 'checked' : ''"
                />
                <label for="classestab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;"
                  >Manage Teachers [{{ assignedTeacherList.length }}]</label
                >
                <div class="tab">
                  <div>
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Teacher Name</div>
                        <!-- <div class="cell">Teacher ID</div> -->
                        <div class="cell">Status</div>
                        <div class="cell">Email</div>
                        <div class="cell">Mobile Number</div>
                        <!-- <div class="cell">Last Modified By</div>
                        <div class="cell">Last Modified Date</div> -->
                        <div class="cell">Action</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(data, index) in assignedTeacherList"
                        :key="index"
                      >
                        <div class="cell" data-title="Teacher Name">
                          {{
                            data.userId
                              ? data.userId.firstName +
                                " " +
                                data.userId.lastName
                              : ""
                          }}
                        </div>
                        <!-- <div
                          class="cell"
                          v-if="data.status == 'Not Joined'"
                          style="color: red"
                          data-title="Teacher Name"
                        >
                        {{ data.userId ? data.userId.isActive ? "Active" : "In-active" : '' }}
                         
                        </div> -->
                        <div
                          class="cell"
                          v-if="data.userId"
                          :style="data.userId.isActive ? 'color: green' : 'color: red'"
                          style="color: #10d010"
                          data-title="Teacher Name"
                        >                        {{ data.userId ? data.userId.isActive ? "Active" : "In-active" : '' }}

                          <!-- {{ data.status }} -->
                        </div>
                        <div class="cell" data-title="Role">
                          {{ data.userId ? data.userId.email ? data.userId.email : '' : '' }}
                        </div>
                        <div class="cell" data-title="Role">
                          {{ data.userId ? data.userId.phoneNo ? data.userId.phoneNo : '' : '' }}
                        </div>
                        <!-- <div class="cell" data-title="Teacher Name">
                          {{
                            data.updatedBy
                              ? data.updatedBy.firstName +
                                " " +
                                data.updatedBy.lastName
                              : ""
                          }}
                        </div>
                        <div class="cell" data-title="Role">
                          {{ data.updatedDate | dateFormat }}
                        </div> -->
                        <div class="cell" data-title="Action">
                          <div
                            class="dispflex"
                            style="justify-content: stretch"
                          >
                            <b-dropdown
                              id="dropdown-1"
                              text=""
                              style="margin-right: 3px"
                            >
                              <b-dropdown-item
                                :disabled="!checkTeacherAssigned(data._id)"
                                @click.prevent="
                                  showAssignedClassPopUp(data._id)
                                "
                                >Assigned Class</b-dropdown-item
                              >
                              <b-dropdown-item
                               :disabled="data.departMent.length == 0"
                                @click.prevent="
                                  viewDepartments(data.departMent)
                                "
                                >Department</b-dropdown-item
                              >
                            </b-dropdown>

                            <button class="btn round orange tooltipt"  @click="editTeacherModal(index)">
                              <i class="fas fa-edit"></i
                              ><span class="tooltiptext">Edit</span>
                            </button>

                            <button class="btn round red tooltipt" @click="showDeleteModal(data.userId._id)"  v-b-modal.deleteteacherPopup >
                            <i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
                        </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  @click="setActiveTab(1)"
                  id="stafftab"
                  :checked="currentTab == 1 ? 'checked' : ''"
                />
                <label for="stafftab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;"
                  >Manage Admins [{{ internalUserList.length }}]</label
                >
                <div class="tab">
                  <div>
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Staff Name</div>
                        <div class="cell">Role</div>
                        <div class="cell">Status</div>
                        <div class="cell">Action</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(data, index) in internalUserList"
                        :key="index"
                      >
                        <div class="cell" data-title="Teacher Name">
                          {{ data.firstName }}
                        </div>
                        <div class="cell" data-title="Role">
                          {{ data.role.name }}
                        </div>
                        <div
                          class="cell"
                          :style="data.isActive ? 'color: green' : 'color: red'"
                          data-title="Status"
                        >
                          {{ data.isActive ? "Active" : "In-active" }}
                        </div>

                        <div class="cell" data-title="Action">
                          <button
                            class="btn round orange tooltipt"
                          
                            @click="viewUserModal(index)"
                          >
                            <i class="fas fa-eye" style="padding-left: 5px;"></i
                            ><span class="tooltiptext">View</span>
                          </button>
                          <button
                            class="btn round orange tooltipt"
                            :disabled="!data.isDeleted"
                            @click="editUserModal(index)"
                          >
                            <i class="fas fa-edit"></i
                            ><span class="tooltiptext">Edit</span>
                          </button>
                          
                          <button
                            class="btn round red tooltipt"
                            v-if="data.isDeleted"
                            :disabled="!data.isActive || getUserId == data._id"
                            @click="showDeleteUserModal(data._id)"
                          >
                            <i class="far fa-trash-alt"></i>
                            <span class="tooltiptext">Delete </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  :checked="currentTab == 2 ? 'checked' : ''"
                  @click="setActiveTab(2)"
                  id="roletab"
                />
                <label for="roletab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Manage Role [{{ roleList.length }}]</label>
                <div class="tab">
                  <div>
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Role Name</div>
                        <div class="cell">Status</div>
                        <div class="cell">Action</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(data, index) in roleList"
                        :key="index"
                      >
                        <div class="cell" data-title="name">
                          {{ data.name }}
                        </div>
                        <div
                          class="cell"
                          style="color: green"
                          data-title="Active"
                        >
                          Active
                        </div>

                        <div class="cell" data-title="Action">
                          <button
                            class="btn round orange tooltipt"
                            v-if="data.isDeleted"
                            @click="editRoleModal(index)"
                          >
                            <i class="fas fa-edit"></i
                            ><span class="tooltiptext">Edit</span>
                          </button>
                          <span v-else>Standard Role </span>
                          <button
                            class="btn round red tooltipt"
                            v-if="data.isDeleted"
                            @click="showRoleDeleteModal(data._id)"
                          >
                            <i class="far fa-trash-alt"></i>
                            <span class="tooltiptext">Delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="addteacherpop" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close"  @click.prevent="closePoup">×</button>
        <!-- <div class="contact-form">
          <h2> {{ editId ? 'Edit Teacher' : 'Add Teacher' }}</h2>
          <br />
          <div>
            <form
              data-vv-scope="teacherValidate"
              id="addteacher"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      v-model="teacher.name"
                      placeholder="Teacher Name"
                      v-validate="'required'"
                    />
                    <label
                    v-if="errors.first('teacherValidate.teacherName')"
                    class="vi-error"
                    for="emailid"
                    style="top:-1em"
                    >Teacher Name is required</label
                  >
                  <label for="tchrnameid" v-else
                    >Teacher Name <span class="required">*</span></label
                  >

                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                  
                       <v-select class="form-control form-input"
                              label="label"
                              name="departMent"
                              multiple
                              v-model="teacher.departMent"
                              :options="getformatClassList"
                              :reduce="label => label.value"
                            ></v-select>

                        <label  >Department </label>
                     
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      @input="checkEmail"
                      v-validate="'required|email'"
                      name="tchremail"
                      v-model="teacher.emailId"
                      class="form-control form-input"
                      id="tchremailid"
                      placeholder="Email Id"
                    />
                    
                    <label
                          v-if="errors.first('teacherValidate.tchremail') || !isEmailIdAvailable "
                          class="vi-error"
                          style="top:-1em"
                          > {{ errors.first('teacherValidate.tchremail') ? 'Email Id is required and (eg: abc@gmail.com)' : 'Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                        >
                        <label  v-else >Email Id <span class="required">*</span></label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 ">
                  <span class="has-float-label">
                    <input
                      type="number" 
                      onKeyPress="if(this.value.length==10) return false;" 
                      @input="checkMobile"
                      v-validate="'required|numeric|min:10|max:10'"
                      name="mbno"
                      v-model="teacher.mobile"
                      class="form-control form-input"
                      id="mbnoid"
                      placeholder="Mobile #"
                    />
                   

                    <label
                          v-if="errors.first('teacherValidate.mbno') || !isMobileAvailable"
                          class="vi-error"
                          style="top:-1em"
                          > {{ errors.first('teacherValidate.mbno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                        >
                        <label  v-else >Mobile <span class="required">*</span></label>
                   
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 "  v-if="editId">
                  <span class="has-float-label">
                    <input
                      v-if="editId"
                      v-validate="'required'"
                      name="staffID"
                      disabled
                      v-model="teacher.staffID"
                      class="form-control form-input"
                      id="staffID"
                      placeholder="Staff ID "
                    />
                   

                    <label
                          v-if="errors.first('teacherValidate.staffID')"
                          class="vi-error"
                          style="top:-1em"
                          > Staff ID is required</label
                        >
                        <label  v-else >Staff ID <span class="required">*</span></label>
                   
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 "  v-if="editId">
                  <span class="has-float-label">
                    <select
                      v-if="editId"
                      v-validate="'required'"
                      name="Status"
                      v-model="teacher.status"
                      class="form-control form-input"
                      id="Status"
                      placeholder="Status"
											>
											<option :value=null>--Select--</option>
											<option v-for="(item,index) in teacherStatusList" :key="index" :value="item.value">
												{{ item.label }}
											</option>
											</select>

                    <label
                          v-if="errors.first('teacherValidate.Status')"
                          class="vi-error"
                          style="top:-1em"
                          > Status is required</label
                        >
                        <label  v-else >Status <span class="required">*</span></label>
                   
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 "  v-if="editId">
                  <span class="has-float-label">
                     <select
                       v-if="editId"
                      v-validate="'required'"
                      name="Employment Type"
                      v-model="teacher.employmentType"
                      class="form-control form-input"
                      id="Employment Type"
                      placeholder="Employment Type"
											>
											<option :value=null>--Select--</option>
											<option v-for="(item,index) in employmentTypeList" :key="index" :value="item.value">
												{{ item.label }}
											</option>
											</select>
                   
                    <label
                          v-if="errors.first('teacherValidate.Employment Type')"
                          class="vi-error"
                          style="top:-1em"
                          > Employment Type is required</label
                        >
                        <label  v-else >Employment Type <span class="required">*</span></label>
                   
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 "  v-if="editId">
                  <span class="has-float-label">
                    <input
                      v-if="editId"
                      max="9999-12-31"
                      onkeydown="return false"
                      v-validate="'required'"
                      name="Date of Joining"
                      v-model="teacher.dateOfJoining"
                      class="form-control form-input"
                      type="date"
                      id="Date of Joining"
                      placeholder="Date of Joining"
                    />
                   

                    <label
                          v-if="errors.first('teacherValidate.Date of Joining')"
                          class="vi-error"
                          style="top:-1em"
                          > Date of Joining is required</label
                        >
                        <label  v-else >Date of Joining <span class="required">*</span></label>
                   
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 "  v-if="editId && teacher.status != 'InActive'">
                  <span class="has-float-label">
                    <input
                      v-if="editId"
                      max="9999-12-31"
                      onkeydown="return false"
                      name="Date of Leaving"
                      v-model="teacher.dateOfLeaving"
                      class="form-control form-input"
                      id="Date of Leaving"
                      type="date"
                      placeholder="Date of Leaving"
                    />
                    <label  >Date of Leaving </label>
                   
                  </span>
                </div>
                 <div class="form-group input-group col-md-6 mt-3 "  v-if="editId && teacher.status == 'InActive'" >
                  <span class="has-float-label">
                    <input
                      v-if="editId"
                      v-validate="'required'"
                      name="Date of Leaving required"
                      v-model="teacher.dateOfLeaving"
                      class="form-control form-input"
                      id="Date of Leaving required"
                      type="date"
                      placeholder="Date of Leaving"
                    />
                   

                    <label
                          v-if="errors.first('teacherValidate.Date of Leaving required')"
                          class="vi-error"
                          style="top:-1em"
                          > Date of Leaving is required</label
                        >
                        <label  v-else >Date of Leaving <span class="required">*</span></label>
                   
                  </span>
                </div>
                
              </div>
              <div
                class="dispflex mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5"
              >
                <div class="dk_icon">
                   <button v-if="editId == null"
                    type="button"
                    class="btn btnsml mpopup"
                    id="svbtn"
                    onclick="saveForm()"
                    title="Create Custom Field"
                  >
                    <i class="fas fa-plus"></i> <span>Custom Field</span>
                  </button> 
                </div>
                <div class="text-right dk_iconsml">
                 
                  <button v-if="editId"
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                     @click.prevent="updateTeacher"
                  >
                      <span>Update</span>
                  </button>
                   <button
                    v-else
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                     @click.prevent="createTeacher"
                  >
                      <span>Save</span>
                  </button>
                  <button type="button" @click.prevent="closePoup" class="btn btncl clsmdl" id="clbtn">
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
         
        </div> -->

        <div class="contact-form" v-if="editTeacherId">
          <!-- <span class="close">&times;</span> -->
          <h2> {{ 'Edit Teacher' }}</h2>

          <br />
          <div>
            <form
              data-vv-scope="teacherValidate"
              id="addteacher"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="editTeacher.userId">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="First Name"
                      class="form-control form-input"
                      id="tchrnameid"
                      v-model="editTeacher.userId.firstName"
                      placeholder="First Name"
                      v-validate="'required'"
                    />
                    <label
                      v-if="errors.first('teacherValidate.First Name')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >First Name is required</label
                    >
                    <label for="tchrnameid" v-else
                      >First Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="editTeacher.userId">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="lastName"
                      class="form-control form-input"
                      id="lastName"
                      v-model="editTeacher.userId.lastName"
                      placeholder="Last Name"
                      v-validate="'required'"
                    />
                    <label
                      v-if="errors.first('teacherValidate.lastName')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Last Name is required</label
                    >
                    <label for="lastName" v-else
                      >Last Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                
                <div class="form-group input-group col-md-6 pr-md-3" v-if="editTeacher.userId">
                  <span class="web-has-float-label">
                    <select
                      v-model="editTeacher.userId.branchId"
                      class="form-control form-input"
                      id="stateid"
                      name="BranchId"
                      v-validate="'required'"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in getAllBranchList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>

                    <label
                      v-if="errors.first('teacherValidate.BranchId')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Branch is required</label
                    >
                    <label for="lastName" v-else
                      >Branch <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3" v-if="editTeacher.userId">
                  <span class="has-float-label">
                    <input
                      type="number"
                      onKeyPress="if(this.value.length==10) return false;"
                      @input="checkMobile"
                      v-validate="'required|numeric|min:10|max:10'"
                      name="mbnoUser"
                      v-model="editTeacher.userId.phoneNo"
                      class="form-control form-input"
                      id="mbnoUser"
                      placeholder="Mobile"
                    />

                    <label
                      v-if="errors.first('teacherValidate.mbnoUser')"
                      class="vi-error"
                      style="top: -1em"
                    >
                      Please enter a valid mobile number(10 digits)</label
                    >
                    <label v-else>Mobile <span class="required">*</span></label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="editTeacher.userId">
                  <span class="has-float-label">
                    <input
                      autocomplete="false"
                      @input="checkTeacherEmail"
                      v-validate="'required|email'"
                      name="emailTest"
                      v-model="editTeacher.userId.email"
                      class="form-control form-input"
                      id="emailTest"
                      placeholder="Email Id"
                    />

                    <label
                      v-if="
                        errors.first('teacherValidate.emailTest') ||
                        !isTeacherEmailIdAvailable
                      "
                      class="vi-error"
                      style="top: -1em"
                    >
                      {{
                        errors.first("teacherValidate.emailTest")
                          ? "Email Id is required and (eg: abc@gmail.com)"
                          : "Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts."
                      }}</label
                    >
                    <label v-else
                      >Email Id <span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <v-select
                      class="form-control form-input"
                      label="label"
                      name="departMent"
                      multiple
                      v-model="editTeacher.departMent"
                      :options="getformatClassList"
                      :reduce="(label) => label.value"
                    ></v-select>

                    <label>Department </label>
                  </span>
                </div>
                <div
                  class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="editTeacher.userId"
                 
                >
                <!-- <div class="cell"> -->
                  <span style="color: #6c757d" class="mr-3">
                    Active 
                        <span class="required">*</span></span
                      >
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              v-model="editTeacher.userId.isActive"
                            />
                            <div class="slider"></div>
                          </label>
                        <!-- </div> -->
                  <!-- <span class="has-float-label">
                    <input type="checkbox" v-model="user.isActive" />

                    <label for="tchrnameid"
                      >Active <span class="required">*</span></label
                    >
                  </span> -->
                </div>

               
                <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="editUserId">
                  <span class="has-float-label">
                    <input type="checkbox" v-model="user.isActive" />

                  <label for="tchrnameid" 
                    >Active <span class="required">*</span></label
                  >

                  </span>
                </div> -->
              </div>
              <div
                class=" mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5"
              >
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">
                  <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="updateTeacher"
                  >
                      <span>Update</span>
                  </button>
                  <button
                    type="button"
                    @click.prevent="closeEditPoup"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="contact-form" v-else>

          <h2>{{ "Create Teacher" }}</h2>
          <br />
          <div>
            <form
              data-vv-scope="teacherValidate"
              id="addteacher"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="First Name"
                      class="form-control form-input"
                      id="tchrnameid"
                      v-model="teacherDetails.firstName"
                      placeholder="First Name"
                      v-validate="'required'"
                    />
                    <label
                      v-if="errors.first('teacherValidate.First Name')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >First Name is required</label
                    >
                    <label for="tchrnameid" v-else
                      >First Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="lastName"
                      class="form-control form-input"
                      id="lastName"
                      v-model="teacherDetails.lastName"
                      placeholder="Last Name"
                      v-validate="'required'"
                    />
                    <label
                      v-if="errors.first('teacherValidate.lastName')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Last Name is required</label
                    >
                    <label for="lastName" v-else
                      >Last Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <!-- <div class="form-group input-group col-md-6 pr-md-3">
                  <span class="web-has-float-label">
                     <select
                          v-model="teacherDetails.role"
                          class="form-control form-input"
                          id="stateid"
                          :disabled="!editRole"
                          name="roleId"
                          v-validate="'required'"
                          placeholder="Role"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in roleList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.name }}
                          </option>
                      </select>
                   
                        <label
                        v-if="errors.first('userValidate.roleId')"
                        class="vi-error"
                        for="emailid"
                        style="top:-1em"
                        >Role is required</label
                      >
                      <label for="lastName" v-else
                        >Role <span class="required">*</span></label
                      >
                  </span>
                </div> -->
                <div class="form-group input-group col-md-6 pr-md-3">
                  <span class="web-has-float-label">
                    <select
                      v-model="teacherDetails.branchId"
                      class="form-control form-input"
                      id="stateid"
                      name="BranchId"
                      v-validate="'required'"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in getAllBranchList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>

                    <label
                      v-if="errors.first('teacherValidate.BranchId')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Branch is required</label
                    >
                    <label for="lastName" v-else
                      >Branch <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      type="number"
                      onKeyPress="if(this.value.length==10) return false;"
                      @input="checkMobile"
                      v-validate="'required|numeric|min:10|max:10'"
                      name="mbnoUser"
                      v-model="teacherDetails.phoneNo"
                      class="form-control form-input"
                      id="mbnoUser"
                      placeholder="Mobile"
                    />

                    <label
                      v-if="errors.first('teacherValidate.mbnoUser')"
                      class="vi-error"
                      style="top: -1em"
                    >
                      Please enter a valid mobile number(10 digits)</label
                    >
                    <label v-else>Mobile <span class="required">*</span></label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      autocomplete="false"
                      @input="checkTeacherEmail"
                      v-validate="'required|email'"
                      name="emailTest"
                      v-model="teacherDetails.email"
                      class="form-control form-input"
                      id="emailTest"
                      placeholder="Email Id"
                    />

                    <label
                      v-if="
                        errors.first('teacherValidate.emailTest') ||
                        !isTeacherEmailIdAvailable
                      "
                      class="vi-error"
                      style="top: -1em"
                    >
                      {{
                        errors.first("teacherValidate.emailTest")
                          ? "Email Id is required and (eg: abc@gmail.com)"
                          : "Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts."
                      }}</label
                    >
                    <label v-else
                      >Email Id <span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <v-select
                      class="form-control form-input"
                      label="label"
                      name="departMent"
                      multiple
                      v-model="selectDepartment"
                      :options="getformatClassList"
                      :reduce="(label) => label.value"
                    ></v-select>

                    <label>Department </label>
                  </span>
                </div>

                <div
                  class="form-group input-group col-md-6 mt-3 pr-md-3"
                  v-if="!editUserId"
                >
                  <span class="has-float-label">
                    <input
                      autocomplete="new-password"
                      name="passwordTest"
                      ref="password"
                      type="password"
                      class="form-control form-input"
                      placeholder="Password"
                      v-validate="'required'"
                      v-model="teacherDetails.password"
                    />
                    <label
                      v-if="errors.first('teacherValidate.passwordTest')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Password is required</label
                    >
                    <label for="tchrnameid" v-else
                      >Password <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      autocomplete="false"
                      type="password"
                      name="Confirm Password"
                      placeholder="Password"
                      data-vv-as="password"
                      class="form-control form-input"
                      v-validate="'required|confirmed:password'"
                      v-model="confirmPassword"
                    />
                    <label
                      v-if="errors.first('teacherValidate.Confirm Password')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Confirm Password should match with Password</label
                    >
                    <label for="tchrnameid" v-else
                      >Confirm Password <span class="required">*</span></label
                    >
                  </span>
                </div>

                <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="editUserId">
                  <span class="has-float-label">
                    <input type="checkbox" v-model="user.isActive" />

                  <label for="tchrnameid" 
                    >Active <span class="required">*</span></label
                  >

                  </span>
                </div> -->
              </div>
              <div
                class="mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5"
              >
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">
                  <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="createTeacher"
                  >
                      <span>Create</span>
                  </button>
                  <button
                    type="button"
                    @click.prevent="closePoup"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <b-modal id="userCreatePopUp" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close"  @click.prevent="closeUserPopUp">×</button>

        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>{{ editUserId ? "Update User" : "Create User" }}</h2>
          <br />
          <div>
            <form
              data-vv-scope="userValidate"
              id="addteacher"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="First Name"
                      class="form-control form-input"
                      id="tchrnameid"
                      v-model="user.firstName"
                      placeholder="First Name"
                      v-validate="'required'"
                    />
                    <label
                      v-if="errors.first('userValidate.First Name')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >First Name is required</label
                    >
                    <label for="tchrnameid" v-else
                      >First Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="lastName"
                      class="form-control form-input"
                      id="lastName"
                      v-model="user.lastName"
                      placeholder="Last Name"
                      v-validate="'required'"
                    />
                    <label
                      v-if="errors.first('userValidate.lastName')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Last Name is required</label
                    >
                    <label for="lastName" v-else
                      >Last Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3">
                  <span class="web-has-float-label">
                    <select
                      v-model="user.branchId"
                      class="form-control form-input"
                      id="stateid"
                      v-validate="'required'"
                      :disabled="!isDisabledUserBranch"
                      name="BranchId"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in getBranchList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>

                    <label
                        v-if="errors.first('userValidate.BranchId')"
                        class="vi-error"
                        for="emailid"
                        style="top:-1em"
                        >Branch is required</label
                      >
                    <label for="lastName" v-else
                      >Branch <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3">
                  <span class="web-has-float-label">
                    <select
                      v-model="user.role"
                      class="form-control form-input"
                      id="stateid"
                      :disabled="!user.branchId"
                      name="roleId"
                      v-validate="'required'"
                      placeholder="Role"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in roleList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>

                    <label
                      v-if="errors.first('userValidate.roleId')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Role is required</label
                    >
                    <label for="lastName" v-else
                      >Role <span class="required">*</span></label
                    >
                  </span>
                </div>
               

                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      type="number"
                      onKeyPress="if(this.value.length==10) return false;"
                      @input="checkMobile"
                      v-validate="'required|numeric|min:10|max:10'"
                      name="mbnoUser"
                      v-model="user.phoneNo"
                      class="form-control form-input"
                      id="mbnoUser"
                      placeholder="Mobile"
                    />

                    <label
                      v-if="errors.first('userValidate.mbnoUser')"
                      class="vi-error"
                      style="top: -1em"
                    >
                      Please enter a valid mobile number(10 digits)</label
                    >
                    <label v-else>Mobile <span class="required">*</span></label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      autocomplete="false"
                      @input="checkUserEmail"
                      v-validate="'required|email'"
                      name="emailTest"
                      v-model="user.email"
                      class="form-control form-input"
                      id="emailTest"
                      placeholder="Email Id"
                    />

                    <label
                      v-if="
                        errors.first('userValidate.emailTest') ||
                        !isUserEmailIdAvailable
                      "
                      class="vi-error"
                      style="top: -1em"
                    >
                      {{
                        errors.first("userValidate.emailTest")
                          ? "Email Id is required and (eg: abc@gmail.com)"
                          : "Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts."
                      }}</label
                    >
                    <label v-else
                      >Email Id <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div
                  class="form-group input-group col-md-6 mt-3 pr-md-3"
                  v-if="!editUserId"
                >
                  <span class="has-float-label">
                    <input
                      autocomplete="new-password"
                      name="passwordTest"
                      ref="password"
                      type="password"
                      class="form-control form-input"
                      placeholder="Password"
                      v-validate="'required'"
                      v-model="user.password"
                    />
                    <label
                      v-if="errors.first('userValidate.passwordTest')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Password is required</label
                    >
                    <label for="tchrnameid" v-else
                      >Password <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div
                  class="form-group input-group col-md-6 mt-3 pr-md-3"
                  v-if="!editUserId"
                >
                  <span class="has-float-label">
                    <input
                      autocomplete="false"
                      type="password"
                      name="Confirm Password"
                      placeholder="Password"
                      data-vv-as="password"
                      class="form-control form-input"
                      v-validate="'required|confirmed:password'"
                      v-model="confirmPassword"
                    />
                    <label
                      v-if="errors.first('userValidate.Confirm Password')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Confirm Password is required and should match with
                      Password and should match with Password</label
                    >
                    <label for="tchrnameid" v-else
                      >Confirm Password <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div
                  class="form-group input-group col-md-6 mt-3 pr-md-3"
                  v-if="editUserId"
                >
                <!-- <div class="cell"> -->
                  <span style="color: #6c757d" class="mr-3">
                    Active 
                        <span class="required">*</span></span
                      >
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              v-model="user.isActive"
                            />
                            <div class="slider"></div>
                          </label>
                        <!-- </div> -->
                  <!-- <span class="has-float-label">
                    <input type="checkbox" v-model="user.isActive" />

                    <label for="tchrnameid"
                      >Active <span class="required">*</span></label
                    >
                  </span> -->
                </div>
              </div>
              <div
                class=" mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5"
              >
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">
                  <button
                    v-if="editUserId"
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="updateUser"
                  >
                      <span>Update</span>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="createUser"
                  >
                      <span>Create</span>
                  </button>
                  <button
                    type="button"
                    @click.prevent="closeUserPopUp"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="userViewPopUp" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close"  @click.prevent="closeUserViewPopUp">×</button>
        <button class="btn round orange tooltipt" v-if="!viewUserEdit" @click="editAction" style="position: absolute;right: 51px;">
                        <i class="fas fa-edit"></i><span class="tooltiptext">Edit</span>
                      </button>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>{{ "View User" }}</h2>
          <br />
          <div>
            <form
              data-vv-scope="userValidate"
              id="addteacher"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="First Name"
                      class="form-control form-input"
                      id="tchrnameid"
                      :disabled="!viewUserEdit"
                      v-model="user.firstName"
                      placeholder="First Name"
                      v-validate="'required'"
                    />
                    <label
                      v-if="errors.first('userValidate.First Name')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >First Name is required</label
                    >
                    <label for="tchrnameid" v-else
                      >First Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="lastName"
                      class="form-control form-input"
                      id="lastName"
                      :disabled="!viewUserEdit"
                      v-model="user.lastName"
                      placeholder="Last Name"
                      v-validate="'required'"
                    />
                    <label
                      v-if="errors.first('userValidate.lastName')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Last Name is required</label
                    >
                    <label for="lastName" v-else
                      >Last Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3">
                  <span class="web-has-float-label">
                    <select
                      v-model="user.branchId"
                      class="form-control form-input"
                      id="stateid"
                      v-validate="'required'"
                      :disabled="!isDisabledUserBranch"
                      name="BranchId"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in getBranchList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>

                    <label
                        v-if="errors.first('userValidate.BranchId')"
                        class="vi-error"
                        for="emailid"
                        style="top:-1em"
                        >Branch is required</label
                      >
                    <label for="lastName" v-else
                      >Branch <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3">
                  <span class="web-has-float-label">
                    <select
                      v-model="user.role"
                      class="form-control form-input"
                      id="stateid"
                      :disabled="!user.branchId || !viewUserEdit"
                      name="roleId"
                      v-validate="'required'"
                      placeholder="Role"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in roleList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>

                    <label
                      v-if="errors.first('userValidate.roleId')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Role is required</label
                    >
                    <label for="lastName" v-else
                      >Role <span class="required">*</span></label
                    >
                  </span>
                </div>
               

                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      type="number"
                      onKeyPress="if(this.value.length==10) return false;"
                      @input="checkMobile"
                      v-validate="'required|numeric|min:10|max:10'"
                      name="mbnoUser"
                      :disabled="!viewUserEdit"
                      v-model="user.phoneNo"
                      class="form-control form-input"
                      id="mbnoUser"
                      placeholder="Mobile"
                    />

                    <label
                      v-if="errors.first('userValidate.mbnoUser')"
                      class="vi-error"
                      style="top: -1em"
                    >
                      Please enter a valid mobile number(10 digits)</label
                    >
                    <label v-else>Mobile <span class="required">*</span></label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      autocomplete="false"
                      @input="checkUserEmail"
                      v-validate="'required|email'"
                      name="emailTest"
                      v-model="user.email"
                      :disabled="!viewUserEdit"
                      class="form-control form-input"
                      id="emailTest"
                      placeholder="Email Id"
                    />

                    <label
                      v-if="
                        errors.first('userValidate.emailTest') ||
                        !isUserEmailIdAvailable
                      "
                      class="vi-error"
                      style="top: -1em"
                    >
                      {{
                        errors.first("userValidate.emailTest")
                          ? "Email Id is required and (eg: abc@gmail.com)"
                          : "Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts."
                      }}</label
                    >
                    <label v-else
                      >Email Id <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div
                  class="form-group input-group col-md-6 mt-3 pr-md-3"
                  v-if="!editUserId"
                >
                  <span class="has-float-label">
                    <input
                      autocomplete="new-password"
                      name="passwordTest"
                      ref="password"
                      type="password"
                      :disabled="!viewUserEdit"
                      class="form-control form-input"
                      placeholder="Password"
                      v-validate="'required'"
                      v-model="user.password"
                    />
                    <label
                      v-if="errors.first('userValidate.passwordTest')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Password is required</label
                    >
                    <label for="tchrnameid" v-else
                      >Password <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div
                  class="form-group input-group col-md-6 mt-3 pr-md-3"
                  v-if="!editUserId"
                >
                  <span class="has-float-label">
                    <input
                      autocomplete="false"
                      type="password"
                      name="Confirm Password"
                      placeholder="Password"
                      :disabled="!viewUserEdit"
                      data-vv-as="password"
                      class="form-control form-input"
                      v-validate="'required|confirmed:password'"
                      v-model="confirmPassword"
                    />
                    <label
                      v-if="errors.first('userValidate.Confirm Password')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Confirm Password is required and should match with
                      Password and should match with Password</label
                    >
                    <label for="tchrnameid" v-else
                      >Confirm Password <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div
                  class="form-group input-group col-md-6 mt-3 pr-md-3"
                  v-if="editUserId"
                >
                <!-- <div class="cell"> -->
                  <span style="color: #6c757d" class="mr-3">
                    Active 
                        <span class="required">*</span></span
                      >
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              :disabled="!viewUserEdit"
                              v-model="user.isActive"
                            />
                            <div class="slider"></div>
                          </label>
                        <!-- </div> -->
                  <!-- <span class="has-float-label">
                    <input type="checkbox" v-model="user.isActive" />

                    <label for="tchrnameid"
                      >Active <span class="required">*</span></label
                    >
                  </span> -->
                </div>
              </div>
              <div
                class=" mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5"
              >
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">
                  <button
                    v-if="viewUserEdit"
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="updateUser"
                  >
                      <span>Update</span>
                  </button>
               
                  <button
                    type="button"
                    @click.prevent="closeUserViewPopUp"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="roleCreatePopUp" no-close-on-backdrop no-close-on-esc>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>{{ editRoleId ? "Update Role" : "Create Role" }}</h2>
          <br />
          <div>
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    @input="checkRole"
                    type="text"
                    name="First Name"
                    class="form-control form-input"
                    id="tchrnameid"
                    v-model="role.name"
                    placeholder="Name"
                  />
                  <label
                    v-if="!isRoleAvailable"
                    class="vi-error"
                    for="emailid"
                    style="top: -1em"
                    >Role Name already exist. please use a different one</label
                  >
                  <label for="tchrnameid" v-else
                    >Role Name <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->
              <div class="text-center dk_iconsml">
                <button
                  v-if="editRoleId"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  :disabled="!role.name || !isRoleAvailable"
                  @click.prevent="updateRole"
                >
                    <span>Update</span>
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  :disabled="!role.name || !isRoleAvailable"
                  @click.prevent="createRole"
                >
                    <span>Create</span>
                </button>
                <button
                  type="button"
                  @click.prevent="closeRolePopUp"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="assignedClassPopUp" no-close-on-backdrop no-close-on-esc>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Assigned Class And Subjects</h2>
          <br />

          <div>
            <div class="form-row">
              <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="selectClass"
                    @change="searchClassName"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in Object.keys(classOptions)"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="acyearid"
                    >Select Class <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="deptlistid"
                    :disabled="!selectClass"
                    v-model="selectSection"
                    @change="searchSection"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in Object.keys(sectionOptions)"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="acyearid"
                    >Select Section <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-2 mt-3 pr-md-3 my-auto"
                style="justify-content: center; cursor: pointer"
              >
                <span>
                  <button
                    @click.prevent="clearSelectValues"
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                    <span>Clear</span>
                  </button>
                </span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-12">
                <div class="widjet">
                  <div class="tbllist mt-1">
                    <div class="restable">
                      <div class="resrow resheader">
                        <!-- <div class="cell">DepartMent </div> -->
                        <div class="cell">Class</div>
                        <div class="cell">Section</div>
                        <div class="cell">Subject</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(data, index) in assignedClassShowList"
                        :key="index"
                      >
                        <!-- <div class="cell" data-title="DepartMent">{{ data.departMent }}</div> -->
                        <div class="cell" data-title="Class">
                          {{ data.className }}
                        </div>
                        <div class="cell" data-title="Section">
                          {{ data.section }}
                        </div>
                        <div class="cell" data-title="Subject">
                          {{
                            data.assignedSubject
                              ? getSubjectName(data.assignedSubject)
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="pt-3 text-right dk_iconsml"
              style="border-top: 1px solid #e5e5e5"
            >
              <button
                @click.prevent="closeAssignedClassPopUp"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="deleteteacherPopup" no-close-on-backdrop no-close-on-esc>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Delete Confirmation</h2>
          <br />
          Are you sure you want to delete this record?
          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="confirmDelete"
            >
                <span>Confirm</span>
            </button>
            <button
              type="button"
              @click.prevent="hideDeletePopUp"
              class="btn btncl clsmdl"
              id="clbtn"
            >
                 <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="deleteUserPopup" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close"  @click.prevent="hideDeletePopUpUser">×</button>

        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Delete Confirmation</h2>
          <br />
          Are you sure you want to delete this record?
          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="confirmDeleteUser"
            >
                <span>Confirm</span>
            </button>
            <button
              type="button"
              @click.prevent="hideDeletePopUpUser"
              class="btn btncl clsmdl"
              id="clbtn"
            >
                 <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="roledeletePopup" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close"  @click.prevent="hideRoleDeleteModal">×</button>

        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Delete Confirmation</h2>
          <br />
          Are you sure you want to delete this record?
          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="confirmDeleteRole"
            >
                <span>Confirm</span>
            </button>
            <button
              type="button"
              @click.prevent="hideRoleDeleteModal"
              class="btn btncl clsmdl"
              id="clbtn"
            >
                 <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="viewDepartmentPopUp" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close"  @click.prevent="closeDepartmentPopUp">×</button>

        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>View Assigned Departments</h2>

          <div>
            <div class="row mt-4">
              <div class="col-lg-12">
                <div class="widjet">
                  <div class="tbllist mt-1">
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">S.No</div>
                        <div class="cell">Department</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(data, index) in assignedDepartments"
                        :key="index"
                      >
                        <div class="cell" data-title="index">
                          {{ index + 1 }}
                        </div>
                        <div class="cell" data-title="DepartMent">
                          {{ getDepartName(data) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="pt-3 text-right dk_iconsml"
              style="border-top: 1px solid #e5e5e5"
            >
              <button
                @click.prevent="closeDepartmentPopUp"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="modalOne" class="modal" no-close-on-backdrop no-close-on-esc>
  <button type="button" class="close" v-if="!isUpdate" @click.prevent="closePoupDepartmemt">×</button>
  <button type="button" class="close" v-if="isUpdate" @click.prevent="closeUpdateModalDepartment">×</button>

<!-- <div class="modal-content"> -->
<div class="contact-form">
<!-- <span class="close">&times;</span> -->
<h2 id="poptitle">Add Department</h2>
<br />
<div id="extFields">
  <form data-vv-scope="department"  accept-charset="utf-8">
    <div class="form-row">
      <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="isUpdate">
        <span class="has-float-label">
          <input
            type="text"
            name="deptmentId"
            v-model="department.departmentId"
            class="form-control form-input"
            id="deptid"
            disabled
            placeholder="Department Id"
          />
          <label
            v-if="errors.first('department.deptmentId')"
            class="vi-error"
            for="emailid"
            style="top:-1em"
            >Department Id is required</label
          >
          <label for="deptid" v-else
            >Department Id <span class="required">*</span></label
          >
        </span>
      </div>
      <div class="form-group input-group col-md-6 mt-3">
        <span class="has-float-label">
          <input
              @input="checkDepartMent"
            v-validate="'required'"
            type="text"
            name="department name"
            v-model.trim="department.departmentName"
            class="form-control form-input"
            id="deptid"
          />
            
                
          <label
            v-if="errors.first('department.department name') || !isDeptNameAvailable "
            class="vi-error"
            for="emailid"
            style="top:-1em"
            >{{ errors.first('department.department name') ? 'Department name is required ' : 'Department name already exist. please use a different one.' }} </label
          >
          <label for="deptid" v-else
            >Department Name <span class="required">*</span></label
          >
        </span>
      </div>
    </div>
    
    <div
      class=" mt-3 pt-3"
      style="border-top: 1px solid #e5e5e5"
    >
      <!-- <div class="dk_icon"> -->
        <!-- <button
          type="button"
          class="btn btnsml mpopup"
          id="svbtn"
          @click.prevent="openModelTwo"
          title="Create Custom Field"
        >
          <i class="fas fa-plus"></i> <span>Standard Departments</span>
        </button> -->
      <!-- </div> -->
      <div class="text-center dk_iconsml">
        <button
          type="button"
          class="btn btnsml"
          id="svbtn"
          @click.prevent="createDepartment"
          v-if="!isUpdate"
        >
            <span>Save</span>
        </button>
        <button
          type="button"
          @click.prevent="closePoupDepartmemt"
          class="btn btncl clsmdl"
          id="clbtn"
          v-if="!isUpdate"
        >
             <span>Cancel</span>
        </button>
        <button
          type="button"
          class="btn btnsml"
          id="svbtn"
          v-if="isUpdate"
          @click.prevent="updateDepartment"
        >
            <span>Update</span>
        </button>
        <button
          type="button"
          @click.prevent="closeUpdateModalDepartment"
          class="btn btncl clsmdl"
          id="clbtn"
          v-if="isUpdate"
        >
             <span>Cancel</span>
        </button>
      </div>
    </div>
  </form>
</div>

</div>

</b-modal>
    </div>
    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="
        width: 100%;
        z-index: 111;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 1rem;
      "
    />
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "teacher",
  // components: {
  //   ViSpinner,
  // },
  data() {
    return {
      teacher: {
        name: "",
        departMent: [],
        emailId: "",
        mobile: null,
        status: "",
        staffID: "",
        branchId: null,
        employmentType: "",
        dateOfJoining: null,
        dateOfLeaving: null,
      },
      editTeacher: {
        name: "",
        departMent: [],
        status: "",
        userId: {}
      },
      department: {
        _id: null,
        departmentId: null,
        departmentName: "",
      },
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNo: null,
        role: null,
        isActive: true,
        branchId: null,
      },
      teacherDetails: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNo: null,
        role: null,
        isActive: true,
        branchId: null,
      },

      role: {
        name: "",
      },
      isDisabledUserBranch: false,
      selectDepartment: [],
      prevDepartmentName: "",
      isDeptNameAvailable: true,

      searchRole: "",
      searchUser: "",
      searchTeacher: "",
      teacherSkip: 0, // set the skip info
      teacherLimit: 8, // set the limit info
      roleSkip: 0, // set the skip info
      roleLimit: 25, // set the limit info
      userSkip: 0, // set the skip info
      userLimit: 25, // set the limit info
      confirmPassword: "",
      preRoleName: "",
      prevEmailId: "", // variable to set the previous emailId
      prevPhno: "", // variable to set the previous phone number
      prevUserEmailId: "",
      editId: null,
      editTeacherId: null,
      editUserId: null,
      userDeleteId: null,
      deleteId: null,
      viLoader: false,
      teacherList: [],
      departmentList: [],
      subjectList: [],
      classOptions: [],
      roleList: [],
      assignedTeacherList: [],
      sectionOptions: [],
      assignedClassAndSubjectList: {},
      assignedMetaData: [],
      assignedClassShowList: [],
      selectClass: null,
      editUserId: null,
      editRoleId: null,
      roleDeleteId: null,
      selectSection: null,
      isRoleAvailable: true,
      isEmailIdAvailable: true,
      isUserEmailIdAvailable: true,
      isTeacherEmailIdAvailable: true,
      isMobileAvailable: true,
      editRole: true,
      viewUserEdit: false,
      assignedDepartments: [],
      currentTab: 0,
      internalUserList: [],
      allInternalUserList: [],
      branchList: [],
      currentUserId: null,
      teacherStatusList: [
        {
          label: "Active",
          value: "Active",
        },

        {
          label: "Not Joined",
          value: "Not Joined",
        },
        {
          label: "InActive",
          value: "InActive",
        },
        {
          label: "Invitation Sent",
          value: "Invitation Sent",
        },
        {
          label: "Joined",
          value: "Joined",
        },
      ],
      employmentTypeList: [
        {
          label: "Permanent",
          value: "Permanent",
        },
        {
          label: "Temporary",
          value: "Temporary",
        },
      ],
    };
  },
  created() {
    this.getDepartMentList();
    this.getTeacherList(true);
    this.getInternalUserList();
    this.getSubjectList();
    this.getRoleList();
    this.getAssignedTeacherDepartmetList();
    this.getBranchDetails();
    this.getDepartMentList();
    this.getDepartMentAllList()
   // this.getCurrentUserDetails()
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    getformatClassList() {
      if (this.departmentList.length > 0) {
        return this.departmentList.map((x) => {
          return {
            label: x.departmentName,
            value: x._id,
          };
        });
      } else {
        return [];
      }
    },
    getUserName() {
      let userData = secureUI.sessionGet("user");
      return (
        userData.userDetails.firstName + " " + userData.userDetails.lastName
      );
    },
    getUserId() {
      let userData = secureUI.sessionGet("user");
      return (
        userData.userDetails._id
      );
    },

    getBranchList() {
      //return this.branchList.filter((x) => x.apiName != "main_branch");
      return this.branchList;
    },
    getAllBranchList() {
      return this.branchList;
    },
  },
  watch: {
    searchTeacher: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        this.getTeacherList(false);
      }
    },
    searchUser: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        //  let dd =    internalUserList.filter(o => Object.keys(o).some(k => o[k].toLowerCase().includes(searchUser.toLowerCase())));
        // this.getInternalUserList();
      }
    },
    searchRole: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        this.getRoleList();
      }
    },
  },
  methods: {
    downloadFile(){
      window.open('https://vidhyaan.sgp1.digitaloceanspaces.com/uploadTeachers.xlsx', "_blank");

    },
    uploadbtn(){
      document.getElementById('importExportID').click();
    },
    previousRoute() {
      // let data = localStorage.getItem("previousTab");
      // localStorage.setItem("activeTab", data);
      // localStorage.setItem("previousTab", data);
      // this.$router.go(-1);
      localStorage.setItem("activeTab", '/admission/home');
        localStorage.setItem("previousTab", '/admission/home');
        this.$router.push({ path: '/admission/home' });
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    setActiveTab(index) {
      this.currentTab = index;
    },
    // closePoup() {
    //   this.isDeptNameAvailable = true;
    //   this.department.departmentId = null;
    //   this.department.departmentName = '';
    //   this.$bvModal.hide("modalOne");
    // },
    closePoupDepartmemt() {
      this.isDeptNameAvailable = true;
      this.department.departmentId = null;
      this.department.departmentName = '';
      this.$bvModal.hide("modalOne");
    },
    
    showUpdateModal(index) {
      let department = this.departmentList[index];
      
      this.department._id = department._id;
      this.department.departmentId = department.departmentId;
      this.department.departmentName = department.departmentName;
      this.prevDepartmentName = department.departmentName
      this.isUpdate = true;
      this.$bvModal.show("modalOne");
    },

    closeUpdateModalDepartment() {
      this.$bvModal.hide("modalOne");
      this.isUpdate = false;
      this.department.departmentId = null;
      this.department.departmentName = '' ;
      this.isDeptNameAvailable = true;
    },

    async updateDepartment() {

      let isFormValid = false;

       await this.$validator.validateAll('department').then(result => {
          isFormValid = result;
       });

      if (isFormValid && this.isDeptNameAvailable) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viXPut(
          `/department/updateDepartment/${this.department._id}`,
          this.department,
          userData.token
        );
       // this.viLoader = null;

        if (response.isSuccess) {
          this.closeUpdateModalDepartment();

           if(this.$route.path != '/admission/department'){
             this.$emit("callDepartMentList");
            }

          this.getDepartMentList();
          this.department.departmentId = null;
          this.department.departmentName = '' ;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
    async createDepartment() {

let isFormValid = false;

await this.$validator.validateAll('department').then(result => {
   isFormValid = result;
});

if (isFormValid && this.isDeptNameAvailable) {

let userData = secureUI.sessionGet("user");

if (!userData) {
 this.$toasted.error("Please login and do the action");
this.$router.push('/login');
} else {
//  this.viLoader = true;

 const response = await ViService.viXPost(
   "/department/createDepartment",
   this.department,
   userData.token
 );
// this.viLoader = null;

 if (response.isSuccess) {

   this.department.departmentId = null;
   this.department.departmentName = '' ;

    if(this.$route.path != '/admission/department'){
      this.$emit("callDepartMentList");
    }

   this.closePoupDepartmemt();
   this.getDepartMentList();
   this.$toasted.success(response.message);
   
   let getCounts = this.$store.getters.getAdmissionObjCounts
   getCounts.departMent = getCounts.departMent + 1

   this.$store.dispatch('setAdmissionObjCOunt', getCounts);

 } else {
   this.$toasted.error(response.message);
    if(response.message == 'Your session has expired, please login'){
         localStorage.removeItem('user');
         this.$router.push('/login');
  }
 }
}
}
},
     async checkDepartMent() {

        let allowDupUser = true;

        if (this.department._id) {
          // check the current key value is changes
          if ( this.department.departmentName == this.prevDepartmentName) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {
          this.department.departmentName = this.department.departmentName.trim()
          
          const details = this.departmentListAll.find((x) => x.departmentName == this.department.departmentName);
           if(details){
              this.isDeptNameAvailable = false
           }else{
              this.isDeptNameAvailable = true
           }
        }
    },
    openUserPoUp() {
      this.user.firstName = "";
      this.user.lastName = "";
      this.user.email = "";
      this.user.branchId = null;
      this.user.role = null;
      this.user.phoneNo = null;
      this.user.password = "";
      this.confirmPassword = "";

      let userData = secureUI.sessionGet("user");

      if (
        userData.userDetails.branch.isAdmin &&
        userData.userDetails.branch._id == localStorage.getItem("currentBranch")
      ) {
        this.user.branchId = localStorage.getItem("currentBranch");
        this.isDisabledUserBranch = true;
      } else {
        this.isDisabledUserBranch = false;
        this.user.branchId = localStorage.getItem("currentBranch");
      }
      this.$bvModal.show("userCreatePopUp");
    },
    openRolePoUp() {
      this.$bvModal.show("roleCreatePopUp");
      (this.editRoleId = null),
        (this.roleDeleteId = null),
        (this.role.name = "");
    },
    async checkRole() {
      let allowDupUser = true;

      if (this.editRoleId) {
        if (this.role.name == this.preRoleName) {
          allowDupUser = false;
        }
      }

      if (allowDupUser) {
        //this.role.name = this.role.name.trim()

        const details = this.roleList.find((x) => x.name == this.role.name);
        if (details) {
          this.isRoleAvailable = false;
        } else {
          this.isRoleAvailable = true;
        }
      }
    },
    closeUserPopUp() {
      this.$bvModal.hide("userCreatePopUp");
      (this.editUserId = null),
        (this.userDeleteId = null),
        (this.isUserEmailIdAvailable = true),
        (this.user.firstName = ""),
        (this.user.lastName = ""),
        (this.user.email = ""),
        (this.user.branchId = null),
        (this.user.role = null),
        (this.user.phoneNo = null),
        (this.editRole = true);
        this.confirmPassword = "";
    },
    closeRolePopUp() {
      this.$bvModal.hide("roleCreatePopUp");
      (this.editRoleId = null),
        (this.roleDeleteId = null),
        (this.role.name = ""),
        (this.isRoleAvailable = true);
    },
    showdrop() {
      var showdp = document.getElementById("showdropmenu");
      if (showdp.style.display === "block") {
        showdp.style.display = "none";
      } else {
        showdp.style.display = "block";
      }
      setTimeout(function () {
        showdp.style.display = "none";
      }, 10000);
      return false;
    },
    checkTeacherAssigned(id) {
      if (this.assignedClassAndSubjectList[id]) {
        return true;
      } else {
        return false;
      }
    },
    getSubjectName(id) {
      return this.subjectList.filter((x) => x._id == id)[0].subjectName;
    },

    getRoleName(id) {
      const roleDetails = this.roleList.find((x) => x._id == id);
      if (roleDetails) {
        return roleDetails.name;
      } else {
        return "";
      }
    },
    closeAssignedClassPopUp() {
      this.$bvModal.hide("assignedClassPopUp");
      this.sectionOptions = [];
      this.classOptions = [];
      (this.selectClass = null),
        (this.selectSection = null),
        (this.assignedMetaData = []),
        (this.assignedClassShowList = []);
    },
    viewDepartments(ids) {
      this.$bvModal.show("viewDepartmentPopUp");
      this.assignedDepartments = ids;
    },
    closeDepartmentPopUp() {
      this.$bvModal.hide("viewDepartmentPopUp");
      this.assignedDepartments = [];
    },
    hideDeletePopUp() {
      this.deleteId = null;
      this.$bvModal.hide("deleteteacherPopup");
    },

    searchClassName() {
      if (this.selectClass) {
        this.selectSection = null;
        this.sectionOptions = [];
        this.assignedClassShowList = this.assignedMetaData.filter(
          (x) => x.className == this.selectClass
        );
        this.sectionOptions = this.classOptions[this.selectClass].reduce(
          (r, a) => {
            r[a.section] = [...(r[a.section] || []), a];
            return r;
          },
          {}
        );
      }
    },
    searchSection() {
      if (this.selectClass && this.selectSection) {
        this.assignedClassShowList = this.assignedMetaData.filter(
          (x) =>
            x.className == this.selectClass && x.section == this.selectSection
        );
      }
    },
    clearSelectValues() {
      this.sectionOptions = [];
      (this.selectClass = null),
        (this.selectSection = null),
        (this.assignedClassShowList = []),
        (this.assignedClassShowList = [...this.assignedMetaData]);
    },
    editUserModal(index) {
      const details = this.internalUserList[index];
      if (details) {
        this.editUserId = details._id;
        (this.user.firstName = details.firstName),
          (this.user.lastName = details.lastName),
          (this.user.email = details.email),
          (this.user.role = details.role._id),
          (this.user.phoneNo = details.phoneNo),
          (this.user.isActive = details.isActive),
          (this.user.isDeleted = details.isDeleted),
          (this.editRole = details.isDeleted);
        (this.prevUserEmailId = details.email),
          (this.user.branchId = details.branch._id),
          this.$bvModal.show("userCreatePopUp");
      }
    },
    viewUserModal(index) {
      this.viewUserEdit = false

      const details = this.internalUserList[index];
      if (details) {
        this.editUserId = details._id;
        (this.user.firstName = details.firstName),
          (this.user.lastName = details.lastName),
          (this.user.email = details.email),
          (this.user.role = details.role._id),
          (this.user.phoneNo = details.phoneNo),
          (this.user.isActive = details.isActive),
          (this.user.isDeleted = details.isDeleted),
          (this.editRole = details.isDeleted);
        (this.prevUserEmailId = details.email),
          (this.user.branchId = details.branch._id),
          this.$bvModal.show("userViewPopUp");
      }
    },
    editAction(){
      this.viewUserEdit = true

    },
    closeUserViewPopUp() {
      this.$bvModal.hide("userViewPopUp");
      (this.editUserId = null),
        (this.userDeleteId = null),
        (this.isUserEmailIdAvailable = true),
        (this.user.firstName = ""),
        (this.user.lastName = ""),
        (this.user.email = ""),
        (this.user.branchId = null),
        (this.user.role = null),
        (this.user.phoneNo = null),
        (this.editRole = true);
        this.confirmPassword = "";
    },
    editTeacherModal(index) {
      const details = this.assignedTeacherList[index];
      this.editTeacherId = details._id
      this.$bvModal.show("addteacherpop");
      this.editTeacher.userId = details.userId
      this.editTeacher.departMent = details.departMent
      this.prevEmailId = this.editTeacher.userId.email
      // {
      //   name: "",
      //   departMent: [],
      //   status: "",
      //   userId: {}
      // },
      
      // if (details) {
      //   this.editUserId = details._id;
      //   (this.user.firstName = details.firstName),
      //     (this.user.lastName = details.lastName),
      //     (this.user.email = details.email),
      //     (this.user.role = details.role._id),
      //     (this.user.phoneNo = details.phoneNo),
      //     (this.user.isActive = details.isActive),
      //     (this.user.isDeleted = details.isDeleted),
      //     (this.editRole = details.isDeleted);
      //   (this.prevUserEmailId = details.email),
      //     (this.user.branchId = details.branch._id),
      //     this.$bvModal.show("userCreatePopUp");
             this.$bvModal.show("addteacherpop");
   
      // }
    },
    

    editRoleModal(index) {
      const details = this.roleList[index];
      if (details) {
        this.editRoleId = details._id;
        (this.role.name = details.name),
          (this.preRoleName = details.name),
          this.$bvModal.show("roleCreatePopUp");
      }
    },
    async getRoleList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/teacher/getRoleList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.roleList = response.data;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getSubjectList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/class/getSubjectList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.subjectList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async createTeacher() {
      let isFormValid = false;

      await this.$validator.validateAll("teacherValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isTeacherEmailIdAvailable && this.isMobileAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          this.viLoader = true;

          let requestBody = {
            user: {
              firstName: this.teacherDetails.firstName,
              lastName: this.teacherDetails.lastName,
              email: this.teacherDetails.email,
              password: this.teacherDetails.password,
              phoneNo: Number(this.teacherDetails.phoneNo),
              branchId: this.teacherDetails.branchId,
            },
            accountDetails: userData.userDetails,
            departMent: this.selectDepartment,
          };

          const response = await ViService.viXPost(
            "/teacher/createTeacher",
            requestBody,
            userData.token
          );
          this.viLoader = null;
          if (response.isSuccess) {
            this.closePoup();
            if (this.$route.path != "/admission/teacher") {
              this.$emit("callteacherList");
            }
            this.getInternalUserList();
            this.getAssignedTeacherDepartmetList();
            this.currentTab = 0;
            this.$toasted.success(response.message);

            let getCounts = this.$store.getters.getAdmissionObjCounts;
            getCounts.teacher = getCounts.teacher + 1;

            this.$store.dispatch("setAdmissionObjCOunt", getCounts);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },
    async updateFile(type,docType) {
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0]

        const fd = new FormData();

        fd.append('doc', docFileDetails, docFileDetails.name,docFileDetails.actType);
        //this.viLoader = true;

        const response = await ViService.viPutOnly('/document/importTeacher',fd,userData.token);

        if (response.isSuccess) {
          this.$toasted.success(response.message);
         // this.getImportData('old');
          this.getInternalUserList();
          this.getAssignedTeacherDepartmetList();
        //   if(!response.type){
        //   this.duplicateMailIds = [],
        //   this.duplicatePhoneNumbers =  []

        //   this.viLoader = false;
          
        //   this.duplicateMailIds = response.data.mailIds || [],
        //   this.duplicatePhoneNumbers = response.data.phoneNumbers || []

        //   if(this.duplicateMailIds.length > 0 || this.duplicatePhoneNumbers.length > 0){
        //     this.$bvModal.show("duplicates"); 
        //   }

        //   if(response.message == 'Upload Successful'){
        //     this.$toasted.success(response.message);
        //    // this.getImportData('old');
        //   }else{
        //     this.$toasted.error(response.message);
        //      if(response.message == 'Your session has expired, please login'){
        //         localStorage.removeItem('user');
        //         this.$router.push('/login');
        //  }
        //   }
         
        //   }

        //   if(response.type){

        //    let requiredFiledData = response.data.reduce((r,data) => {
        //         r[data.row] = [...r[data.row] || [], data];
        //         return r;
        //     }, {});
            
        //    this.requiredMessage = response.message

        //    this.requiredFields = requiredFiledData || {}

        //    this.$bvModal.show("requiredFieldsPopUp"); 
           
        //   }
        } else {
           //this.viLoader = false;
          this.$toasted.error(response.message);
        }
       this.viLoader = false;
        document.getElementById("importExportID").value=null; 

      }

    },
    async updateTeacher() {
      let isFormValid = false;

      await this.$validator.validateAll("teacherValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isTeacherEmailIdAvailable ) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          this.viLoader = true;
//           this.editTeacher.departMent = []

// this.editTeacherId = null

          let requestBody = {
            user: {
              firstName: this.editTeacher.userId.firstName,
              lastName: this.editTeacher.userId.lastName,
              email: this.editTeacher.userId.email,
              phoneNo: Number(this.editTeacher.userId.phoneNo),
              _id: this.editTeacher.userId._id,
              isActive: this.editTeacher.userId.isActive
            },
            assignedDepartMent: {
              _id: this.editTeacherId,
              isActive: this.editTeacher.userId.isActive,
              departMent: this.editTeacher.departMent
            }
           // departMent: this.selectDepartment,
          };
          
          const response = await ViService.viXPut(`/teacher/updateTeacher/${this.editTeacherId}`,requestBody,userData.token);
          this.viLoader = null;
          if (response.isSuccess) {
            this.closeEditPoup();

            if (this.$route.path != "/admission/teacher") {
              this.$emit("callteacherList");
            }

            this.getTeacherList(false);
            this.getAssignedTeacherDepartmetList();

            this.editId = null;
            this.currentTab = 0;
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },
    async createUser() {
      let isFormValid = false;

      await this.$validator.validateAll("userValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isUserEmailIdAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          this.viLoader = true;
          let requestBody = {
            user: {
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              email: this.user.email,
              role: this.user.role,
              password: this.user.password,
              phoneNo: Number(this.user.phoneNo),
              branchId: this.user.branchId,
            },
            accountDetails: userData.userDetails,
          };
          const response = await ViService.viXPost(
            "/user/createInternalUser",
            requestBody,
            userData.token
          );
          this.viLoader = null;
          if (response.isSuccess) {
            this.$bvModal.hide("userCreatePopUp");

            setTimeout(() => {
              this.closeUserPopUp();
              this.getInternalUserList();
            }, 3000);

            let getCounts = this.$store.getters.getAdmissionObjCounts;
            getCounts.admins = getCounts.admins + 1;

            this.$store.dispatch("setAdmissionObjCOunt", getCounts);
            this.currentTab = 1;
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },

    async createRole() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.role.name = this.role.name.trim();

        const details = this.roleList.find((x) => x.name == this.role.name);
        if (details) {
          this.isRoleAvailable = false;
        } else {
          this.isRoleAvailable = true;

          const response = await ViService.viXPost(
            "/teacher/createRole",
            this.role,
            userData.token
          );
          if (response.isSuccess) {
            this.closeRolePopUp();
            this.getRoleList();
            this.currentTab = 2;
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },
    async updateUser() {
      let isFormValid = false;

      await this.$validator.validateAll("userValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isUserEmailIdAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          this.viLoader = true;
          let requestBody = {
            user: {
              _id: this.editUserId,
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              email: this.user.email,
              role: this.user.role,
              phoneNo: Number(this.user.phoneNo),
              isActive: this.user.isActive,
              isDeleted: this.user.isDeleted,
              branchId: this.user.branchId,
            },
            accountDetails: userData.userDetails,
          };
          const response = await ViService.viXPost(
            "/user/updateInternalUser",
            requestBody,
            userData.token
          );
          this.viLoader = null;
          if (response.isSuccess) {
            this.$bvModal.hide("userCreatePopUp");

            setTimeout(() => {
              this.closeUserPopUp();
              this.getInternalUserList();
            }, 3000);
            this.currentTab = 1;
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },
    async getBranchDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        //this.viLoader = true
        const response = await ViService.viXGet(
          "/account/getBranchDetails",
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);
          this.branchList = details;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        // this.viLoader = false
      }
    },
    async updateldTeacher() {
      let isFormValid = false;

      await this.$validator.validateAll("teacherValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isEmailIdAvailable && this.isMobileAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          // this.viLoader = true;

          const response = await ViService.viXPut(
            `/teacher/updateTeacher/${this.editId}`,
            this.teacher,
            userData.token
          );
          this.viLoader = null;

          if (response.isSuccess) {
            this.closePoup();

            if (this.$route.path != "/admission/teacher") {
              this.$emit("callteacherList");
            }

            this.getTeacherList(false);
            this.editId = null;
            this.currentTab = 0;
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },

    async updateRole() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;

        const response = await ViService.viXPut(
          `/teacher/updateRole/${this.editRoleId}`,
          this.role,
          userData.token
        );

        if (response.isSuccess) {
          this.closeRolePopUp();
          this.getRoleList();
          this.currentTab = 2;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    async checkEmail() {
      let allowDupUser = true;

      if (this.editId) {
        if (this.teacher.emailId == this.prevEmailId) {
          allowDupUser = false;
        }
      }

      if (allowDupUser) {
        this.teacher.emailId = this.teacher.emailId.trim();
        this.teacher.emailId = this.teacher.emailId.toLowerCase();

        const details = this.teacherList.find(
          (x) => x.emailId == this.teacher.emailId
        );
        if (details) {
          this.isEmailIdAvailable = false;
        } else {
          this.isEmailIdAvailable = true;
        }
      }
    },
    async checkMobile() {
      let allowDupUser = true;

      if (this.editId) {
        if (this.teacher.mobile == this.prevPhno) {
          allowDupUser = false;
        }
      }

      if (allowDupUser) {
        this.teacher.mobile = this.teacher.mobile.trim();

        const details = this.teacherList.find(
          (x) => x.mobile == this.teacher.mobile
        );
        if (details) {
          this.isMobileAvailable = false;
        } else {
          this.isMobileAvailable = true;
        }
      }
    },
    async checkUserEmail() {
      let allowDupUser = true;

      if (this.editUserId) {
        if (this.user.email == this.prevUserEmailId) {
          allowDupUser = false;
        }
      }

      if (allowDupUser) {
        this.teacher.emailId = this.teacher.emailId.trim();
        this.teacher.emailId = this.teacher.emailId.toLowerCase();

        const response = await ViService.viGetOnly(
          `/common/checkApiName?cName=User&email=${this.user.email}`
        );
        if (response.isSuccess) {
          this.isUserEmailIdAvailable = response.isApiNameAvailable;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async checkTeacherEmail() {
      let allowDupUser = true;
      let mailId = ''

      if (this.editTeacherId) {
         
        if (this.editTeacher.userId.email == this.prevEmailId) {
          allowDupUser = false;
        }else{
          mailId = this.editTeacher.userId.email
        }
      }else{
        mailId = this.teacherDetails.email
      }

      if (allowDupUser) {
       // this.teacher.emailId = this.teacher.emailId.trim();
       mailId = mailId.toLowerCase();

        const response = await ViService.viGetOnly(
          `/common/checkApiName?cName=User&email=${mailId}`
        );
        if (response.isSuccess) {
          this.isTeacherEmailIdAvailable = response.isApiNameAvailable;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    
    openTeacherPopUp() {
      this.teacherDetails.branchId = localStorage.getItem("currentBranch");
      this.$bvModal.show("addteacherpop");
    },
    closePoup() {
      this.$bvModal.hide("addteacherpop");
      this.isTeacherEmailIdAvailable = true
      this.prevEmailId = "";
      this.prevPhno = "";
      this.confirmPassword = "";
      this.teacher.emailId = "";
      this.teacher.mobile = null;
      this.teacher.name = "";
      this.teacher.departMent = [];
      (this.editId = null), (this.teacher.status = "");
      this.teacher.staffID = "";
      this.teacher.employmentType = "";
      this.teacher.dateOfJoining = null;
      (this.teacher.dateOfLeaving = null),
        (this.teacherDetails.firstName = ""),
        (this.teacherDetails.lastName = ""),
        (this.teacherDetails.email = ""),
        (this.teacherDetails.password = ""),
        (this.teacherDetails.phoneNo = null),
        (this.teacherDetails.role = null),
        (this.teacherDetails.branchId = null),
        (this.teacherDetails.isActive = true),
        (this.selectDepartment = []);
    },
    closeEditPoup(){

      this.$bvModal.hide("addteacherpop");
      
      this.editTeacher.departMent = []

      this.editTeacherId = null
      this.prevEmailId = "";
      this.isTeacherEmailIdAvailable = true;

      this.prevPhno = "";
      this.confirmPassword = "";
      this.teacher.emailId = "";
      this.teacher.mobile = null;
      this.teacher.name = "";
      this.teacher.departMent = [];
      (this.editId = null), (this.teacher.status = "");
      this.teacher.staffID = "";
      this.teacher.employmentType = "";
      this.teacher.dateOfJoining = null;
      (this.teacher.dateOfLeaving = null),
        (this.teacherDetails.firstName = ""),
        (this.teacherDetails.lastName = ""),
        (this.teacherDetails.email = ""),
        (this.teacherDetails.password = ""),
        (this.teacherDetails.phoneNo = null),
        (this.teacherDetails.role = null),
        (this.teacherDetails.branchId = null),
        (this.teacherDetails.isActive = true),
        (this.selectDepartment = []);
    },

    showAssignedClassPopUp(teacherId) {
      this.classOptions = [];
      (this.assignedMetaData = []), (this.assignedClassShowList = []);

      const assignedClassList = this.assignedClassAndSubjectList[teacherId];
      (this.assignedMetaData = assignedClassList),
        (this.assignedClassShowList = [...assignedClassList]);

      this.classOptions = this.assignedClassShowList.reduce((r, a) => {
        r[a.className] = [...(r[a.className] || []), a];
        return r;
      }, {});

      this.$bvModal.show("assignedClassPopUp");
    },

    async getTeacherList(value) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (value) {
          this.viLoader = true;
        }

        const response = await ViService.viXGet(
          `/teacher/getTeacherListView?skip=${this.teacherSkip}&limit=${this.teacherLimit}&search=${this.searchTeacher}`,
          userData.token
        );
        if (response.isSuccess) {
          const finalData = secureUI.secureGet(response.data);
          this.teacherList = finalData.teacherList;
          this.assignedClassAndSubjectList = finalData.assignedClass;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    async getAssignedTeacherDepartmetList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        //  if(value){
        //    this.viLoader = true;
        //  }

        const response = await ViService.viXGet(
          `/teacher/getAssignedTeacherDepartmetList`,
          userData.token
        );
        if (response.isSuccess) {
          const finalData = secureUI.secureGet(response.data);

          this.assignedTeacherList = finalData;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        // this.viLoader = false;
      }
    },

    async getInternalUserList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/teacher/getInternalUserList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);
        
          if (
            userData.userDetails.branch.isAdmin &&
            userData.userDetails.branch._id ==
              localStorage.getItem("currentBranch")
          ) {
            this.internalUserList = data.filter(
              (x) => x.profile.name == "School Admin"
            );
          } else {
            const filterUser = data.filter(
              (x) => x.branch._id == localStorage.getItem("currentBranch")
            );
            this.internalUserList = filterUser.filter(
              (x) => x.profile.name == "School Admin"
            );
          }
          //  this.allInternalUserList = secureUI.secureGet(response.data);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    async sendInvite(teacherId, emailId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          `/teacher/sendInvite/${teacherId}/${emailId}`,
          userData.token
        );
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getTeacherList(false);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    getDepartName(departMentId) {
      if (departMentId) {
        return this.departmentList.find((x) => x._id == departMentId)
          .departmentName;
      } else {
        return this.departmentList.find((x) => x._id == departMentId)
          .departmentName;
      }
    },

    async getDepartMentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/department/getDepartments?viewType=list",
          userData.token
        );

        if (response.isSuccess) {
          this.departmentList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    // editTeacherModal(index) {
    //   const teacherDetails = this.teacherList[index];
    //   this.prevEmailId = teacherDetails.emailId;
    //   this.prevPhno = teacherDetails.mobile;
    //   this.teacher.name = teacherDetails.name;
    //   this.teacher.departMent = teacherDetails.departMent;
    //   this.teacher.emailId = teacherDetails.emailId;
    //   this.teacher.mobile = teacherDetails.mobile;
    //   this.teacher.status = teacherDetails.status;
    //   this.teacher.staffID = teacherDetails.staffID;
    //   this.teacher.employmentType = teacherDetails.employmentType;
    //   this.teacher.dateOfJoining = teacherDetails.dateOfJoining
    //     ? teacherDetails.dateOfJoining.substring(0, 10)
    //     : null;
    //   this.teacher.dateOfLeaving = teacherDetails.dateOfLeaving
    //     ? teacherDetails.dateOfLeaving.substring(0, 10)
    //     : null;

    //   this.editId = teacherDetails._id;
    //   this.$bvModal.show("addteacherpop");
    // },
    showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deleteteacherPopup");
    },
    showDeleteUserModal(id) {
      this.userDeleteId = id;
      this.$bvModal.show("deleteUserPopup");
    },
    hideDeletePopUpUser() {
      this.userDeleteId = null;
      this.$bvModal.hide("deleteUserPopup");
    },
    showRoleDeleteModal(id) {
      this.roleDeleteId = id;
      this.$bvModal.show("roledeletePopup");
    },
    hideRoleDeleteModal() {
      this.roleDeleteId = null;
      this.$bvModal.hide("roledeletePopup");
    },

    async confirmDeleteRole() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/teacher/deleteRole/${this.roleDeleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$bvModal.hide("roledeletePopup");
          this.roleDeleteId = null;
          this.getRoleList();
          this.currentTab = 2;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    async confirmDeleteUser() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        // this.viLoader = true;
        const response = await ViService.viDelete(
          `/teacher/deleteInternalUser/${this.userDeleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$bvModal.hide("deleteUserPopup");
          this.userDeleteId = null;
          let getCounts = this.$store.getters.getAdmissionObjCounts;
          getCounts.admins = getCounts.admins - 1;

          this.$store.dispatch("setAdmissionObjCOunt", getCounts);
          this.getInternalUserList();
          this.currentTab = 1;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },

    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/teacher/deleteTeacher/${this.deleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$bvModal.hide("deleteteacherPopup");
          this.deleteId = null;
          this.getTeacherList(false);
          this.getInternalUserList();
          this.getAssignedTeacherDepartmetList();
          //this.getTeacherList(false);
          this.currentTab = 0;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
  },
  components: {
    "v-select": Select,
    ViSpinner,
  },
};
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#roleCreatePopUp .modal-content {
    width: 37% !important; /* Could be more or less, depending on screen size */
	
}

</style>
